import React, { useState } from 'react';
import { Client, Databases, ID } from 'appwrite';
import PhoneInput from 'react-phone-input-2';

const RightButtonPopup = ({ onClose }) => {
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [name, setName] = useState('');
  const [designation, setDesignation] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmailId] = useState('');
  const [errors, setErrors] = useState({});

  const client = new Client().setEndpoint(process.env.REACT_APP_ENDPOINT).setProject(process.env.REACT_APP_PROJECT_ID);

  const databases = new Databases(client);

  const handlePhoneNumberChange = (value) => {
    // Remove all non-numeric characters except for leading '+'
    const cleanedValue = value.replace(/[^0-9+]/g, '');
    setPhoneNumber(cleanedValue);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await databases.createDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLLECTION_ID,
        ID.unique(),
        {
          name,
          designation,
          companyName,
          phoneNumber, // Directly use the phone number
          email,
        }
      );
      // Close the modal and show success message

      setIsSuccessOpen(true);

      // Navigate to a FC App in new tab with query params email and name
      const redirectUrl = `${process.env.REACT_APP_FORM_REDIRECT_URL}/?name=${name}&email=${email}`;
      window.open(redirectUrl, '_blank');
    } catch (error) {
      console.error('Error creating document:', error);
    }
  };

  const closeSuccessModal = () => {
    setIsSuccessOpen(false);
  };

  return (
    <div className='fixed font-display bottom-0  right-1 z-50 '>
      <div className='bg-[#F1F6FF] max-w-md w-full max-h-screen py-0 border border-gray-300 rounded-lg shadow-md p-8    relative  lg:w-96 lg:h-96 overflow-y-auto lg:overflow-block'>
        <button className='absolute top-2 right-2 text-gray-500 hover:text-gray-700' onClick={onClose}>
          <svg
            className='w-6 h-6'
            fill='none'
            stroke='currentColor'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12'></path>
          </svg>
        </button>
        <div className='text-xl mt-4 md:mt-5  font-bold text-center mb-4  '>
          Experience the Power of Data
          <br /> With
          <span className='text-[#C62B6D] font-bold'> AI Fortune </span>
          Cookie
        </div>
        <form className='px-5 ' onSubmit={handleSubmit}>
          <div className='mb-4'>
            <label className='block text-gray-700'>Name</label>
            <input
              type='text'
              name='name'
              value={name}
              onChange={(e) => setName(e.target.value)}
              className='w-full  px-3 py-2  border border-gray-300 rounded'
              required
            />
            {errors.name && <p className='text-red-500 text-sm'>{errors.name}</p>}
          </div>
          <div className='mb-4'>
            <label className='block text-gray-700'>Email Address</label>
            <input
              type='email'
              name='email'
              value={email}
              onChange={(e) => setEmailId(e.target.value)}
              className='w-full px-3 py-2   border border-gray-300 rounded'
              required
            />
            {errors.email && <p className='text-red-500 text-sm'>{errors.email}</p>}
          </div>
          <div className='mb-4'>
            <label className='block text-gray-700'>Phone Number</label>
            <PhoneInput
              country={'in'}
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              inputClass='custom-phone-input2'
              buttonClass='border rounded'
              dropdownClass='border rounded'
            />
            {errors.phoneNumber && <p className='text-red-500 text-sm'>{errors.phoneNumber}</p>}
          </div>
          <div className='mb-4'>
            <label className='block text-gray-700'>Designation</label>
            <input
              type='text'
              name='designation'
              value={designation}
              onChange={(e) => setDesignation(e.target.value)}
              className='w-full px-3 py-2  border border-gray-300 rounded'
            />
            {errors.designation && <p className='text-red-500 text-sm'>{errors.designation}</p>}
          </div>
          <div className='mb-4'>
            <label className='block text-gray-700'>Company Name</label>
            <input
              type='text'
              name='companyName'
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              className='w-full px-3 py-2  border border-gray-300 rounded'
              required
            />
            {errors.companyName && <p className='text-red-500 text-sm'>{errors.companyName}</p>}
          </div>
          <div className='mb-4'>
            <button type='submit' className='w-full px-4 py-2 bg-[#C62B6D] text-white rounded'>
              Submit
            </button>
          </div>
        </form>

        {isSuccessOpen && (
          <div className='fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center'>
            <div className='relative bg-white p-8 rounded-lg max-w-md w-full text-center'>
              <button className='absolute top-2 right-2 text-gray-500 hover:text-gray-700' onClick={closeSuccessModal}>
                <svg
                  className='w-6 h-6'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12'></path>
                </svg>
              </button>
              <h2 className='text-2xl font-bold mb-4'>Success!</h2>
              <p className='mb-4'>Your request has been sent successfully.</p>
              <button className='px-4 py-2 bg-[#C62B6D] text-white rounded' onClick={closeSuccessModal}>
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RightButtonPopup;
