import React, { useState, useEffect, useRef } from 'react';
import { Client, Databases, ID } from 'appwrite';
import './style.css';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import landingPage_fc from '../assets/landingPage_fc.svg';
import spiral from '../assets/spiral.png';
import llmc from '../assets/llmc.gif';
import pinkarrow from '../assets/pinkarrow.png';
import aug from '../assets/aug.gif';
import lock from '../assets/lock.gif';
import chain from '../assets/chain.gif';
import search from '../assets/search.gif';
import multi from '../assets/multi.gif';

import pattern from '../assets/Pattern.svg';

import logo from '../assets/logo.svg';

import arrowup from '../assets/arrowup.png';

import arrowdown from '../assets/Arrowdown.png';

import itGif from '../assets/it.gif';
import hrGif from '../assets/hr.gif';
import salesGif from '../assets/sales.gif';
import marketingGif from '../assets/marketing.gif';
import financeGif from '../assets/finance.gif';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import salesModel from '../assets/salesModel.png';
import hrModel from '../assets/HRModel.png';
import renameImage from '../assets/rename.png';
import marketingModel from '../assets/marketingModel.png';
import rename2Image from '../assets/rename2.png';

import itPrompt from '../assets/ITPrompt.png';
import hrPrompt from '../assets/HRPrompt.png';
import salesPrompt from '../assets/salesPrompt.png';
import marketingPrompt from '../assets/marketingPrompt.png';
import financePrompt from '../assets/financePrompt.png';

import itPromptBg from '../assets/ITPromptBg.png';
import hrPromptBg from '../assets/HRPromptBg.png';
import salesPromptBg from '../assets/salesPromptBg.png';
import financePromptBg from '../assets/financePromptBg.png';

const posts = [
  {
    idx: 0,
    title: 'How LLMs Enhance Knowledge Management Systems',
    description:
      'Imagine a busy law firm where Sarah, a seasoned attorney, grappled with the inefficiencies of a traditional Knowledge Management System (KMS), struggling to efficiently navigate through vast legal documents. Recognizing the need for a change, the firm embraced artificial intelligence, integrating Large Language Models (LLMs) into their KMS. The impact was transformative the LLM-powered system became a virtual legal assistant, revolutionizing the search, review, and summarization of complex legal documents. This case study unfolds the story of how the fusion of human expertise and AI not only streamlined operations but also significantly enhanced customer satisfaction.',
    date: '7th March, 2024',
    cover:
      'https://i0.wp.com/randomwalk.ai/wp-content/uploads/2024/02/Knowledge-Management-Systems-Random-Walk.png?w=1291&ssl=1',
    link: 'https://randomwalk.ai/blog/how-llms-enhance-knowledge-management-systems/',
  },
  {
    idx: 1,
    title: 'Practical Strategies for Cost-Effective and High-Performance LLMs',
    description:
      'Large language models (LLMs) are reshaping how we interact with machines, generating human-quality text, translating languages, and writing different kinds of creative content. But this power comes at a cost. Training and running LLMs can be expensive, limiting their accessibility for many businesses and researchers.',
    date: '15th April, 2024',
    cover:
      'https://i0.wp.com/randomwalk.ai/wp-content/uploads/2024/04/knowledge-management-system-1.jpg?resize=2048%2C1366&ssl=1',
    link: 'https://randomwalk.ai/blog/practical-strategies-for-cost-effective-and-high-performance-llms/',
  },
  {
    idx: 2,
    title: 'Rethinking RAG: Can Knowledge Graphs Be the Answer?',
    description:
      'Knowledge Management Systems (KMS) have long been the backbone for organizing information within organizations. While large language models (LLMs) aid in natural language-based information retrieval from KMS, they may lack specific organizational data. Retrieval-augmented generation (RAG) bridges this gap by retrieving contextually relevant information from KMS using vector databases that store data as mathematical vectors, capturing word meanings and relationships within documents. It feeds this information to the LLM, empowering it to generate more accurate and informative responses.',
    date: '25th April, 2024',
    cover:
      'https://i0.wp.com/randomwalk.ai/wp-content/uploads/2024/04/Knowledge-management-system-1.jpg?resize=2048%2C1366&ssl=1',
    link: 'https://randomwalk.ai/blog/rethinking-rag-can-knowledge-graphs-be-the-answer/',
  },
  {
    idx: 3,
    title: 'How RAGs Empower Semantic Understanding for Enterprise LLMs',
    description:
      'Large Language Models (LLMs) have become a transformative force within the enterprise landscape to enhance business efficiency and gain a competitive edge. LLMs trained on massive datasets excel at identifying patterns and generating text, but they can struggle with the inherent complexities of human communication, particularly when it comes to understanding the deeper meaning and context behind a user query. This is where Retrieval-Augmented Generation (RAGs) technology emerges as a powerful tool for enhancing an LLM’s semantic understanding.',
    date: '10th May, 2024',
    cover: 'https://i0.wp.com/randomwalk.ai/wp-content/uploads/2024/05/large-language-model-LLM.png?w=1291&ssl=1',
    link: 'https://randomwalk.ai/blog/how-rags-empower-semantic-understanding-for-enterprise-llms/',
  },
  {
    idx: 4,
    title: 'How to Prepare Your Enterprise Systems for Seamless LLM Chatbot Integration',
    description:
      'Enterprise chatbots hold the promise of transforming internal communication in organizations, but they are currently presented by a challenge. Limited natural language processing (NLP) capabilities lead to repetitive interactions, misunderstandings, and an inability to address complex issues. This frustrates users and hinders chatbot adoption',
    date: '14th May, 2024',
    cover:
      'https://i0.wp.com/randomwalk.ai/wp-content/uploads/2024/05/AI-integration-services.jpg?resize=2048%2C1365&ssl=1',
    link: 'https://randomwalk.ai/blog/how-to-prepare-your-enterprise-systems-for-seamless-llm-chatbot-integration/',
  },
];

const tabs = ['Information Technology', 'Human Resources', 'Sales', 'Marketing', 'Finance'];
const tabGifs = [itGif, hrGif, salesGif, marketingGif, financeGif];
const tabText = [
  'Optimizes tasks like software provisioning, password resets, and troubleshooting, for efficient IT operations.',
  'Manage leave request, access payroll and benefits, share updates and offer personalized HR assistance.',
  'Access account details, authorize discounts, track metrics, and streamline client communication.',
  'Access brand guidelines, create copy, track campaign performance and engage audiences.',
  'Submit purchase orders, generate financial reports, manage risk, and access accounting data.',
];
const tabModels = [salesModel, hrModel, renameImage, marketingModel, rename2Image];
const tabPrompt = [itPrompt, hrPrompt, salesPrompt, marketingPrompt, financePrompt];
const tabPromptBg = [itPromptBg, salesPromptBg, financePromptBg, hrPromptBg, financePromptBg];

const tabs2 = [
  'Refine Your Objectives with our Workshop',
  'Data Source Evaluation and Enhancement',
  'Vector Database and Knowledge Graph Creation',
  'Defining Database Queries',
  'Custom LLMs and Natural Language Queries',
];

const tabContent = {
  'Refine Your Objectives with our Workshop': [
    'Clarify your goals through interactive workshops',
    'Develop custom solutions with our industry experts',
  ],
  'Data Source Evaluation and Enhancement': [
    'Evaluate data sources for quality and relevance',
    'Streamline updates for seamless knowledge graph integration',
  ],
  'Vector Database and Knowledge Graph Creation': [
    'Integrate diverse data into knowledge graphs',
    'Utilize graph databases for storage and vector databases for swift analysis',
  ],
  'Defining Database Queries': [
    'Refine queries and gain insights from compiled data',
    'Use scalable graph database for seamless information integration',
  ],
  'Custom LLMs and Natural Language Queries': [
    'Enhance data accessibility with custom LLMs and knowledge graphs',
    'Empower decision-making and innovation',
  ],
};

const Homepage = () => {
  const [isHovered, setIsHovered] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);

  const [name, setName] = useState('');
  const [designation, setDesignation] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmailId] = useState('');
  const [utmSource, setUtmSource] = useState('');
  const [utmMedium, setUtmMedium] = useState('');
  const [utmCampaign, setUtmCampaign] = useState('');
  const [utmId, setUtmId] = useState('');
  const [utmTerm, setUtmTerm] = useState('');
  const [utmContent, setUtmContent] = useState('');

  const [errors, setErrors] = useState({});

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const client = new Client().setEndpoint(process.env.REACT_APP_ENDPOINT).setProject(process.env.REACT_APP_PROJECT_ID);

  const databases = new Databases(client);

  const handlePhoneNumberChange = (value) => {
    // Remove all non-numeric characters except for leading '+'
    const cleanedValue = value.replace(/[^0-9+]/g, '');
    setPhoneNumber(cleanedValue);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    setUtmSource(searchParams.get('utm_source') || '');
    setUtmMedium(searchParams.get('utm_medium') || '');
    setUtmCampaign(searchParams.get('utm_campaign') || '');
    setUtmId(searchParams.get('utm_id') || '');
    setUtmTerm(searchParams.get('utm_term') || '');
    setUtmContent(searchParams.get('utm_content') || '');
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await databases.createDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLLECTION_ID,
        ID.unique(),
        {
          name,
          designation,
          companyName,
          phoneNumber,
          email,
          utmSource,
          utmMedium,
          utmCampaign,
          utmId,
          utmTerm,
          utmContent,
        }
      );
      // Close the modal and show success message
      setIsModalOpen(false);
      setIsSuccessOpen(true);
      // Navigate to a FC App in new tab with query params email and name
      // and set cookie access-token with eY....
      document.cookie = `access-token=${process.env.REACT_APP_JWT_TOKEN}`;
      const redirectUrl = `${process.env.REACT_APP_FORM_REDIRECT_URL}/?name=${name}&email=${email}`;
      window.open(redirectUrl, '_blank');
    } catch (error) {
      console.error('Error creating document:', error);
    }
  };

  const closeSuccessModal = () => {
    setIsSuccessOpen(false);
  };

  const [activeIdx, setActiveIdx] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIdx((prevIdx) => (prevIdx < posts.length - 1 ? prevIdx + 1 : 0));
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const cards = document.querySelectorAll('.fade-in-sequential');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            // Apply a delay based on the card's index
            setTimeout(() => {
              entry.target.classList.add('fade-in');
              observer.unobserve(entry.target); // Unobserve to prevent multiple animations
            }, index * 800); // Adjust the delay as needed (150ms per card)
          }
        });
      },
      {
        threshold: 0.6, // Trigger when at least 60% of the element is visible
      }
    );

    cards.forEach((card) => {
      observer.observe(card);
    });

    // Clean up observer on component destruction
    return () => {
      cards.forEach((card) => observer.unobserve(card));
    };
  }, []); // Empty dependency array ensures effect runs only once

  const [currentTab, setCurrentTab] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  // Effect for automatic tab rotation
  useEffect(() => {
    const id = setInterval(() => {
      setCurrentTab((currentTab + 1) % tabs2.length);
    }, 5000);
    setIntervalId(id);

    return () => clearInterval(id);
  }, [currentTab]); // Restart effect when currentTab changes

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTab((currentTab + 1) % tabs.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [currentTab]);

  const [menuOpen, setMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  const signupRef = useRef(null);

  useEffect(() => {
    // Check if the URL contains #signup
    if (window.location.hash === '#signup') {
      signupRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleScroll = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setActiveSection(entry.target.id);
      }
    });
  };

  useEffect(() => {
    const options = {
      threshold: 0.5, // Adjust this value to determine when a section is considered in view
    };

    const observer = new IntersectionObserver(handleScroll, options);

    const sections = document.querySelectorAll('section');
    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  const handleSetActiveLink = (link) => {
    setActiveLink(link);
  };

  const [activeLink, setActiveLink] = useState('t1'); // Default active link

  useEffect(() => {
    // Function to handle scroll events
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      // Calculate the top offset of each section
      const t1Section = document.getElementById('t1').offsetTop;
      const t2Section = document.getElementById('t2').offsetTop;
      const t3Section = document.getElementById('t3').offsetTop;
      const t4Section = document.getElementById('t4').offsetTop;

      // Determine which section is in view based on scroll position
      if (scrollPosition >= t1Section && scrollPosition < t2Section - 100) {
        setActiveLink('t1');
        setMenuOpen(false);
      } else if (scrollPosition >= t2Section - 100 && scrollPosition < t3Section - 100) {
        setActiveLink('t2');
        setMenuOpen(false);
      } else if (scrollPosition >= t3Section - 100 && scrollPosition < t4Section - 100) {
        setActiveLink('t3');
        setMenuOpen(false);
      } else if (scrollPosition >= t4Section - 100) {
        setActiveLink('t4');
        setMenuOpen(false);
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  const handleNextTab = () => {
    setCurrentTab((currentTab + 1) % tabs.length);
  };

  const tabTexts = ['Now', 'Week 1', 'Week 2', 'Week 3', 'Week 4'];

  const [previousTab, setPreviousTab] = useState(null);

  useEffect(() => {
    if (previousTab !== null) {
      setTransitionDirection(currentTab > previousTab ? 'forward' : 'backward');
    }
    setPreviousTab(currentTab);
  }, [currentTab]);

  const handleTabChange = (index) => {
    setPreviousTab(currentTab);
    setCurrentTab(index);
  };

  const [transitionDirection, setTransitionDirection] = useState('forward');

  const handleTabClick = (index) => {
    if (index > currentTab) {
      setTransitionDirection('forward');
    } else if (index < currentTab) {
      setTransitionDirection('backward');
    }
    setCurrentTab(index);
  };

  return (
    <>
      <div className='font-display '>
        <div
          id='t1'
          className='bg-custom-gradient5 max-md:hidden relative min-h-fit h-fit flex items-center justify-center text-white max-md:flex-col snap-center snap-always pb-32 pt-12  md:pt-0'
        >
          <div className='absolute -top-20 left-0 pl-4 pt-1'>
            <img src={logo} alt='Top Left' className='w-52 h-52 object-contain' />
          </div>

          <div className='z-10 flex flex-grow flex-col items-start mt-10  pl-5 md:pl-16 lg:ml-12 2xl:ml-16 lg:-mt-12 2xl:-mt-16 '>
            <div className='text-md md:text-xl lg:text-4xl font-black 2xl:text-5xl py-2'>AI Fortune Cookie</div>
            <div className='text-sm font-bold md:text-xs lg:text-sm md:font-medium 2xl:text-lg py-1 pb-5'>
              Enable a culture of knowledge sharing, break data silos and automate employee support
            </div>
            <button
              className='text-grayy text-xs md:text-xs lg:text-base 2xl:text-xl font-bold bg-white hover:bg-blue-800 hover:text-white hover:border-white border border-transparent rounded-md py-3 px-10'
              onClick={handleButtonClick}
            >
              Schedule a Call
            </button>
          </div>
          <img
            src={landingPage_fc}
            alt='AI Fortune Cookie'
            className='p-0 md:pt-10 md:p-0 lg:mr-10 md:mr-5 mt-8 px-5 object-contain md:w-7/12 lg:w-6/12 2xl:w-[6/12] lg:mb-10 fade-in max-md:order-1'
          />
        </div>

        <div
          id='t1'
          className='bg-blue-800 md:hidden  relative min-h-fit h-fit flex flex-col items-start justify-center text-white pb-5 md:pb-0 pt-12 lg:pt-16 md:pt-20'
        >
          <div className='absolute -top-20 left-0 p-4'>
            <img src={logo} alt='Top Left' className='w-52 h-52 object-contain' />
          </div>
          <div className='z-10 flex  flex-col items-start md:-mt-20 mt-10 lg:mt-0 pl-5 md:pl-16 text-left '>
            <div className='text-md md:text-xl lg:text-3xl font-black py-2'>AI Fortune Cookie</div>
            <div className='text-xs font-medium md:text-xs lg:text-base md:font-medium '>
              Enable a culture of knowledge sharing, break data silos and automate employee support
            </div>
            <img
              src={landingPage_fc}
              alt='AI Fortune Cookie'
              className='p-0 md:pt-10 md:p-0  mt-5 pr-5 object-contain md:w-7/12 fade-in max-md:mb-8'
            />
          </div>
          <button
            className='border md:hidden text-xs ml-10  md:text-xs lg:text-sm 2xl:text-xl text-black font-bold border-white bg-white hover:bg-blue-800 rounded-md py-3 px-7 mt-0'
            onClick={handleButtonClick}
          >
            Request a demo
          </button>
        </div>

        <div class='min-h-full max-md:hidden flex flex-col items-center justify-center snap-center snap-always relative'>
          <img
            src={spiral}
            alt='Abstract shape1'
            className='absolute left-0 bottom-0 w-9/12 h-auto md:w-[390px] lg:w-[600px] md:h-auto 2xl:w-[750px] 2xl:h-auto 2xl:-mt-40 md:-ml-5 object-fill opacity-32 z-0 pointer-events-none'
          />

          <div class='relative w-full h-0 -translate-y-full -mt-1 '>
            <div className='triangle1  hidden md:block '></div>
          </div>
          <div class='relative w-full h-0 -translate-y-full -mb-1'>
            <div className='triangle   md:!border-b-[125px] !border-b-primary opacity-50'></div>
          </div>
          <div class='relative w-full h-0 -translate-y-full -mb-2'>
            <div className='triangle !border-b-[90px]  md:!border-b-[170px] !border-b-blue opacity-20'></div>
          </div>
        </div>

        <div id='t2' className='bg-[#F5F5F5]  flex items-center justify-center snap-center snap-always pt-5 w-full'>
          <div className='flex flex-col w-full '>
            <div className='top  text-grayy md:px-16 lg:px-20 2xl:px-48 w-full'>
              <div className='md:text-xl pb-10 lg:pb-16 2xl:pb-10  lg:text-3xl  text-center font-extrabold px-8 md:pb-10 2xl:text-3xl pt-8 text-base md:mt-0'>
                Discover the Power of AI Fortune Cookie
              </div>
            </div>

            <div className='text-grayy md:px-20 lg:px-5 grid grid-cols-2 md:grid-cols-3 gap-12 md:gap-x-24 md:gap-y-20 lg:gap-x-0 lg:gap-y-24 2xl:gap-x-0 2xl:gap-y-20 w-full'>
              <div className='rounded-lg flex flex-col items-center'>
                <div className='border-blue-800 border-2 rounded-xl fade-in-sequential flex flex-col items-center glass'>
                  <img
                    src={llmc}
                    alt='Feature 1'
                    className='lg:w-28 lg:h-28 w-20 h-20 md:w-20 md:h-20 object-contain md:scale-75'
                  />
                </div>
                <div className='md:text-md text-md lg:text-xl 2xl:text-xl mt-2 lg:mt-5 text-center'>
                  Customized LLMs
                </div>
              </div>

              <div className='rounded-lg flex flex-col items-center'>
                <div className='border-blue-800 border-2 rounded-xl fade-in-sequential flex flex-col items-center glass'>
                  <img
                    src={aug}
                    alt='Feature 2'
                    className='lg:w-28 lg:h-28 w-20 h-20 md:w-20 md:h-20 object-contain md:scale-75'
                  />
                </div>
                <div className='md:text-md text-md lg:text-xl 2xl:text-xl mt-2 lg:mt-5 text-center'>
                  Augmented Analytics
                </div>
              </div>

              <div className='rounded-lg flex flex-col items-center'>
                <div className='border-blue-800 border-2 rounded-xl fade-in-sequential flex flex-col items-center glass'>
                  <img
                    src={lock}
                    alt='Feature 3'
                    className='lg:w-28 lg:h-28 w-20 h-20 md:w-20 md:h-20 object-contain md:scale-75'
                  />
                </div>
                <div className='md:text-md text-md lg:text-xl 2xl:text-xl mt-2 lg:mt-5 text-center'>Data Security</div>
              </div>

              <div className='md:-mt-10 rounded-lg flex flex-col items-center'>
                <div className='border-blue-800 border-2 rounded-xl fade-in-sequential flex flex-col items-center glass'>
                  <img
                    src={chain}
                    alt='Feature 4'
                    className='lg:w-28 lg:h-28 w-20 h-20 md:w-20 md:h-20 object-contain md:scale-75'
                  />
                </div>
                <div className='md:text-md text-md lg:text-xl 2xl:text-xl mt-2 lg:mt-5 text-center'>
                  Link All Data Sources
                </div>
              </div>

              <div className='md:-mt-10 rounded-lg flex flex-col items-center'>
                <div className='border-blue-800 border-2 rounded-xl fade-in-sequential flex flex-col items-center glass'>
                  <img
                    src={search}
                    alt='Feature 5'
                    className='lg:w-28 lg:h-28 w-20 h-20 md:w-20 md:h-20 object-contain md:scale-75'
                  />
                </div>
                <div className='md:text-md text-md lg:text-xl 2xl:text-xl mt-2 lg:mt-5 text-center'>
                  Enterprise Search
                </div>
              </div>

              <div className='md:-mt-10 rounded-lg flex flex-col items-center'>
                <div className='border-blue-800 border-2 rounded-xl fade-in-sequential flex flex-col items-center glass'>
                  <img
                    src={multi}
                    alt='Feature 6'
                    className='lg:w-28 lg:h-28 md:w-20 w-20 h-20 md:h-20 object-contain md:scale-75'
                  />
                </div>
                <div className='md:text-md text-md lg:text-xl 2xl:text-xl mt-2 lg:mt-5 text-center'>Tailored UX/UI</div>
              </div>
            </div>

            <div className='flex justify-center md:my-10 my-10'>
              <button
                className={`py-3 px-10 border-t border-b border-r border-l bg-[#3E59B7] text-white hover:bg-white  hover:text-black hover:border-black md:text-sm rounded-md transition-colors duration-300`}
                onClick={handleButtonClick}
              >
                Talk to our AI Experts
              </button>
            </div>
          </div>
        </div>

        <div id='t3'>
          <div className='relative bg-custom-gradient4 flex py-10 md:py-0 md:p-0 md:px-10 md:pb-0 lg:px-32 2xl:px-96 items-center min-h-0 max-sm:min-h-[10rem] h-full justify-center text-white overflow-clip max-md:pr-1 2xl:pt-20 pt-5 md:pt-0 lg:pt-16'>
            <div className='absolute md:top-0 top-[70px] right-3 z-50 arrow-button-wrapper'>
              <img src={pinkarrow} alt='Next' className='md:hidden w-5 h-5 cursor-pointer' onClick={handleNextTab} />
            </div>
            <div className='absolute max-md:hidden  top-0 2xl:top-3 right-3  p-4'>
              <p className='text-sm font-bold text-pink-500 lg:text-base 2xl:text-xl '>Organizational Use Cases</p>
            </div>

            <div className='relative flex flex-col items-center p-3 md:p-0 justify-center w-full'>
              <p className='-mt-3 md:hidden pb-6  lg:-mt-10 lg:ml-[900px] ml-52 lg:text-xl text-pink-600  text-sm   font-bold'>
                Organizational Use Cases
              </p>
              <div className='relative w-full '>
                <div className=' max-md:hidden md:pb-0 md:mt-12 lg:mt-0 mt-0 2xl:-mt-3 relative flex items-center justify-center gap-8  w-full'>
                  <div
                    className='absolute bottom-0 left-0 h-1 bg-pink-500 transition-transform duration-300 ease-in-out'
                    style={{
                      transform: `translateX(${currentTab * (100 / tabs.length)}%)`,
                    }}
                  ></div>
                  {tabs.map((tab, i) => (
                    <button
                      key={i}
                      className={`tab lg:min-w-40 min-w-40 md:min-w-20   rounded-md p-2 text-sm md:text-md  font-semibold transition-transform duration-300 ease-in-out ${
                        currentTab === i ? 'scale-105 bg-[#C62B6D]  text-white' : 'bg-transparent'
                      }`}
                      onClick={() => handleTabClick(i)}
                    >
                      {tab}
                    </button>
                  ))}
                </div>
              </div>
              <div className='   md:mt-28 lg:mt-24 md:pb-10  grid flex-grow grid-cols-1 grid-rows-1 w-full'>
                {tabs.map(
                  (tab, idx) =>
                    currentTab === idx && (
                      <div
                        key={idx}
                        className='flex items-start justify-center tab-content flex-col col-span-full row-span-full relative w-full h-full max-md:pb-4 snap-center snap-always'
                      >
                        <p className='text-sm font-bold py-2 ml-4'>{tabs[idx]}</p>
                        <div className='flex flex-col md:flex-row'>
                          <div>
                            <img
                              src={tabGifs[idx]}
                              alt={tabs[idx]}
                              className='w-32 h-32 object-contain text-ellipsis'
                            />
                            <div className='w-11/12 ml-3 md:w-2/6 md:mr-20 text-sm z-40 flex min-h-36 mb-2'>
                              {tabText[idx]}
                            </div>
                            <div className='lg:-mt-10   max-md:hidden'>
                              <button
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                className={`py-3 px-10 border-t  border-b border-r border-l border-white ${
                                  isHovered ? 'bg-white text-black' : 'bg-custom-gradient4 text-white'
                                } md:text-sm rounded-md transition-colors duration-300`}
                                onClick={handleButtonClick}
                              >
                                Talk to our AI Experts
                              </button>
                            </div>
                          </div>

                          <div className='flex py-1'>
                            <img
                              src={tabModels[idx]}
                              alt='Abstract shape1'
                              className={`absolute max-md:hidden lg:w-4/12 2xl:w-4/12 pointer-events-none w-1/2 md:w-4/12 object-cover -bottom-10 md:left-1/2 right-0 translate-x-1/3 md:-translate-x-1/2 z-10 ${
                                idx === 2 || idx === 4 ? 'scale-125 origin-bottom' : ''
                              }`}
                            />
                            <img
                              src={tabPromptBg[idx]}
                              alt='text box'
                              className={`absolute pointer-events-none w-8/12 md:w-7/12 lg:w-5/12 object-cover max-md:scale-150 -bottom-10 right-0 ${
                                idx === 3 ? 'translate-y-36' : ''
                              }`}
                            />
                            <img
                              src={tabPrompt[idx]}
                              alt='text box'
                              className='max-md:relative max-md:w-8/12 mr-10  md:mr-16 lg:mr-0   md:w-5/12  lg:w-4/12  2xl:w-4/12  lg:-translate-x-5 lg:-translate-y-1/4 2xl:-translate-x-6 2xl:-translate-y-28 md:absolute max-md:pb-5 max-md:translate-x-1/4 items-center max-md:scale-150 pointer-events-none  object-cover md:right-16 md:translate-x-14 md:-translate-y-1/4'
                            />
                          </div>
                        </div>
                      </div>
                    )
                )}
              </div>
              <div className='md:hidden ml-5 tabsContainer mt-10 md:mt-0 flex items-center justify-start gap-4 w-full pb-0'>
                {tabs.map((tab, i) => (
                  <button
                    key={i}
                    className={`tab min-w-1 z-10 rounded-full p-2 text-sm font-semibold hover:scale-110 transition-transform duration-300 ${
                      currentTab === i ? 'scale-105 text-primary p-[0.4rem] bg-pink-500' : 'p-[0.3rem] bg-[#dadada]'
                    }`}
                    onClick={() => handleTabClick(i)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <div
          id='t4'
          className='flex md:pb-10 pb-5 flex-col overflow-hidden items-center justify-center bg-white text-[#565656] lg:pb-10 snap-center snap-always pt-5 lg:pt-8 md:pt-0'
        >
          <div className='px-7 container relative flex items-center justify-center flex-col gap-8 min-h-fit'>
            <p className='text-[#565656] text-base md:text-2xl lg:text-3xl lg:max-w-2xl text-center font-extrabold mt-0 md:mt-20 lg:mt-10 md:pb-5 '>
              From Idea To Production in just a few weeks
            </p>

            <div className='dots progress max-md:hidden mt-8 md:mt-19 lg:mt-10 pb-20 bar flex items-center justify-between max-w-[65rem] gap-2 w-full relative'>
              {tabs2.map((tab, i) => (
                <div className='grid h-6 w-6 p-0 grid-cols-1 grid-rows-1 relative' key={i}>
                  {i === 0 && (
                    <div className='text-xs md:text-sm absolute text-nowrap -translate-y-6 font-bold'>Now</div>
                  )}
                  {i === 1 && (
                    <div className='text-xs md:text-sm absolute text-nowrap translate-y-7 -translate-x-3 font-bold'>
                      Week 1
                    </div>
                  )}
                  {i === 2 && (
                    <div className='text-xs md:text-sm absolute text-nowrap -translate-y-6 -translate-x-3 font-bold'>
                      Week 2
                    </div>
                  )}
                  {i === 3 && (
                    <div className='text-xs md:text-sm absolute text-nowrap translate-y-7 -translate-x-3 font-bold'>
                      Week 3
                    </div>
                  )}
                  {i === 4 && (
                    <div className='text-xs md:text-sm absolute text-nowrap -translate-y-6 -translate-x-6 font-bold'>
                      4-6 Weeks
                    </div>
                  )}

                  <button
                    onClick={() => handleTabClick(i)}
                    className={` relative h-6 w-6 rounded-full max-md:hidden z-20  transition-all flex items-center justify-center col-span-full row-span-full ${
                      i <= currentTab ? 'bg-[#3E59B7]  text-white' : 'bg-[#E0E0E7]  text-[#707070]'
                    }`}
                  >
                    <div className={`h-3 w-3 rounded-full ${i === currentTab ? 'bg-white' : 'bg-white'}`}></div>
                  </button>
                </div>
              ))}

              {currentTab === 0 && (
                <div className='absolute left-0 transform top-[-23px] md:top-[-40px] translate-x-4 md:translate-x-6 lg:translate-x-8  md:scale-115'>
                  <img src={arrowup} alt='Arrow Up' className=' lg:w-[220px] h-auto md:w-40 md:h-10 text-[#3E59B7]' />
                </div>
              )}
              {currentTab === 1 && (
                <div className='absolute md:bottom-0 bottom-0 md:left-0 md:scale-125 transform  md:translate-x-[200px] lg:translate-x-[300px] translate-x-[115px] -translate-y-[60px] lg:-translate-y-[30px] md:-translate-y-10'>
                  <img
                    src={arrowdown}
                    alt='Arrow Down'
                    className=' lg:w-[185px] h-auto md:w-36 md:h-auto text-[#3E59B7]'
                  />
                </div>
              )}
              {currentTab === 2 && (
                <div
                  className={`absolute ${
                    currentTab % 2 === 0 ? '' : 'top-6'
                  } md:-top-11 top-[-23px] left-0 md:left-1 md:scale-125 transform -translate-x-24 md:-translate-x-40  lg:-translate-x-56`}
                  style={{
                    width: 'calc(100% / 4)',
                    marginLeft: `${(currentTab + 1) * (100 / 4)}%`,
                  }}
                >
                  <img src={arrowup} alt='Arrow Up' className=' lg:w-[180px] h-auto md:w-32 md:h-10 text-[#3E59B7]' />
                </div>
              )}
              {currentTab === 3 && (
                <div className='absolute bottom-20 md:bottom-[0px] left-0 md:left-0 transform md:-translate-y-10 md:translate-x-[550px]  lg:translate-x-[810px] scale-125 lg:-translate-y-8 translate-x-[320px]'>
                  <img
                    src={arrowdown}
                    alt='Arrow Down'
                    className=' lg:w-[180px] h-7 md:w-32  lg:h-auto md:h-auto text-[#3E59B7]'
                  />
                </div>
              )}

              <div className='line progress w-full h-1 bg-[#E0E0E7] absolute'></div>
              <div
                className='line progress h-1 absolute left-0 transition-all'
                style={{
                  width: `${currentTab * 25}%`,
                  backgroundColor: currentTab === tabs2.length - 1 ? '#3E59B7' : '#3E59B7',
                }}
              ></div>
            </div>

            <div className='relative max-lg:hidden w-full flex md:-mt-8 lg:mt-0 2xl:mt-0 -mt-10 md:justify-start flex-col md:flex-row lg:px-24 2xl:px-52  lg:-translate-y-8 '>
              {tabs2.map((tab, index) => {
                const isEntering = currentTab === index;
                return (
                  <CSSTransition
                    key={index}
                    in={isEntering}
                    timeout={500}
                    classNames={transitionDirection === 'forward' ? 'slide' : 'slide-backward'}
                    unmountOnExit
                  >
                    <div
                      className={`relative  w-full rounded-xl transition-transform md:justify-start duration-500 ${
                        isEntering ? 'bg-[#3E59B7] text-white  lg:w-5/12' : 'hidden'
                      }`}
                      style={{
                        height: 'auto',
                        transform: `translateX(${index * 155}px)`,
                      }}
                    >
                      <div className='p-5'>
                        <div
                          className={`text-xs -ml-1 md:text-sm lg:text-base font-bold ${
                            isEntering ? 'text-left' : 'text-center'
                          }`}
                        >
                          {tab}
                        </div>
                        {isEntering && (
                          <ul className='text-xs md:text-sm lg:text-sm font-light mt-1 list-disc ml-3'>
                            {tabContent[tab].map((content, idx) => (
                              <li key={idx}>{content}</li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </CSSTransition>
                );
              })}
            </div>

            <div className='relative max-md:hidden lg:hidden  w-full flex md:-mt-8 lg:mt-5 2xl:mt-0 -mt-10 md:justify-start flex-col md:flex-row lg:pr-0 md:pr-5 lg:-translate-y-8 '>
              {tabs2.map((tab, index) => {
                const isEntering = currentTab === index;
                return (
                  <CSSTransition
                    key={index}
                    in={isEntering}
                    timeout={500}
                    classNames={transitionDirection === 'forward' ? 'slide' : 'slide-backward'}
                    unmountOnExit
                  >
                    <div
                      className={`relative  w-full rounded-xl transition-transform md:justify-start duration-500 ${
                        isEntering ? 'bg-[#3E59B7] text-white w-auto  md:w-7/12' : 'hidden'
                      }`}
                      style={{
                        height: 'auto',
                        transform: `translateX(${index * 80}px)`,
                      }}
                    >
                      <div className='p-5'>
                        <div
                          className={`text-xs md:text-md lg:text-base font-bold ${
                            isEntering ? 'text-left' : 'text-center'
                          }`}
                        >
                          {tab}
                        </div>
                        {isEntering && (
                          <ul className='text-xs md:text-xs lg:text-sm font-light mt-2 list-disc pl-2'>
                            {tabContent[tab].map((content, idx) => (
                              <li key={idx}>{content}</li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </CSSTransition>
                );
              })}
            </div>

            <div className='flex flex-row md:hidden gap-3 overflow-x-auto w-full max-md:hidden'>
              {tabs2.map((tab, index) => (
                <button
                  key={index}
                  onClick={() => handleTabClick(index)}
                  className={`px-3 py-1 whitespace-nowrap rounded-lg ${
                    index === currentTab ? 'bg-[#3E59B7] text-white' : 'bg-[#E0E0E7] text-[#707070]'
                  }`}
                >
                  {tab}
                </button>
              ))}
            </div>

            <div className='flex flex-row md:hidden gap-3 overflow-x-auto w-full hide-scrollbar'>
              {tabs2.map((tab, index) => (
                <div key={index} className='flex flex-col items-center'>
                  <div className='mb-2 text-center text-xs translate-y-11 mr-3'>{tabTexts[index]}</div>
                  <div className={`relative z-0 flex-grow w-0 min-w-32 mt-10 mb-12 rounded-xl bg-[#3E59B7] text-white`}>
                    {index === tabs2.length - 1 ? null : index % 2 ? (
                      <img src={arrowup} alt='Arrow Up' className='w-32 absolute -top-[55px] z-20 -left-20' />
                    ) : (
                      <img src={arrowdown} alt='Arrow Down' className='w-32 absolute -bottom-8 z-20 left-52' />
                    )}
                    <div className={`p-5 text-left`}>
                      <div className='text-xs lg:text-md font-semibold'>{tab}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div>
          {isModalOpen && (
            <div className='fixed z-50 inset-0 md:p-0 p-10  bg-black bg-opacity-50 flex items-center justify-center'>
              <div className='relative bg-[#F1F6FF]   p-8 rounded-lg max-h-screen   max-w-md w-full'>
                <button className='absolute top-2 right-2 text-gray-500 hover:text-gray-700' onClick={closeModal}>
                  <svg
                    className='w-6 h-6'
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12'></path>
                  </svg>
                </button>
                <div className='text-lg md:text-xl text-center font-bold mb-4'>
                  Experience the Power of Data <br />
                  With
                  <span className='text-[#C62B6D] font-bold'> AI Fortune </span>
                  Cookie
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='mb-4'>
                    <label className='block text-gray-700'>Name</label>
                    <input
                      type='text'
                      name='name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className='w-full px-3 py-2 border rounded'
                      required
                    />
                    {errors.name && <p className='text-red-500 text-sm'>{errors.name}</p>}
                  </div>
                  <div className='mb-4'>
                    <label className='block text-gray-700'>Email Address</label>
                    <input
                      type='email'
                      name='email'
                      value={email}
                      onChange={(e) => setEmailId(e.target.value)}
                      className='w-full px-3 py-2 border rounded'
                      required
                    />
                    {errors.email && <p className='text-red-500 text-sm'>{errors.email}</p>}
                  </div>
                  <div className='mb-4'>
                    <label className=' text-gray-700'>Phone Number</label>
                    <PhoneInput
                      country={'in'}
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      inputClass='custom-phone-input2'
                      buttonClass='border rounded'
                      dropdownClass='border rounded'
                    />
                    {errors.phoneNumber && <p className='text-red-500 text-sm'>{errors.phoneNumber}</p>}
                  </div>
                  <div className='mb-4'>
                    <label className='block text-gray-700'>Designation</label>
                    <input
                      type='text'
                      name='designation'
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                      className='w-full px-3 py-2 border rounded'
                    />
                    {errors.designation && <p className='text-red-500 text-sm'>{errors.designation}</p>}
                  </div>

                  <div className='mb-4'>
                    <label className='block text-gray-700'>Company Name</label>
                    <input
                      type='text'
                      name='companyName'
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      className='w-full px-3 py-2 border rounded'
                      required
                    />
                    {errors.companyName && <p className='text-red-500 text-sm'>{errors.companyName}</p>}
                  </div>
                  <div className='mb-4'>
                    <button type='submit' className='w-full px-4 py-2 bg-[#C62B6D] text-white rounded'>
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {isSuccessOpen && (
            <div className='fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center'>
              <div className='relative bg-white p-8 rounded-lg max-w-md w-full text-center'>
                <button
                  className='absolute top-2 right-2 text-gray-500 hover:text-gray-700'
                  onClick={closeSuccessModal}
                >
                  <svg
                    className='w-6 h-6'
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12'></path>
                  </svg>
                </button>
                <h2 className='text-2xl font-bold mb-4'>Success!</h2>
                <p className='mb-4'>Your request has been sent successfully.</p>
                <button className='px-4 py-2 bg-[#C62B6D] text-white rounded' onClick={closeSuccessModal}>
                  Close
                </button>
              </div>
            </div>
          )}
        </div>

        <div className='lg:px-16 md:pb-10   px-5 md:p-0 pb-5 2xl:mx-auto 2xl:mr-36 2xl:container md:px-5  bg-white'>
          <div className='relative w-full bg-custom-gradient6 rounded-xl'>
            <div className='absolute max-md:hidden lg:left-12 md:left-8 top-0 bottom-0 flex items-center'>
              <img src={pattern} alt='Background' className='h-auto md:w-7/12 lg:w-8/12 object-cover opacity-50' />
            </div>
            <div className='relative   md:px-0 md:py-0 lg:px-4 lg:py-3'>
              <div className='absolute lg:top-52 2xl:top-52 top-5  left-7 lg:left-20 md:top-48 md:left-16 font-extrabold lg:text-3xl text-xl text-white'>
                <span className='lg:text-3xl text-xl   md:text-2xl  2xl:text-4xl font-bold'>
                  Experience the Power of <br />
                  Data with AI Fortune Cookie <br />
                </span>
                <span className='lg:text-base md:text-xs text-sm 2xl:text-lg font-medium'>
                  Access your AI Potential in just 15 mins!
                </span>
                <br />
                <br />
              </div>

              <div className='flex justify-end '>
                <div className='flex-1 max-w-md lg:mr-10 '>
                  <form
                    ref={signupRef}
                    onSubmit={handleSubmit}
                    className='p-8  pt-32 md:pt-5 md:pr-auto md:py-4 md:ml-32 lg:mx-5 lg:py-6  lg:p-0 relative  rounded-lg '
                  >
                    <div className='mb-4'>
                      <label className='block text-white'>Name</label>
                      <input
                        type='text'
                        name='name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className='w-full bg-[#7187ED] px-3 py-2 border rounded'
                        required
                      />
                      {errors.name && <p className='text-red-500 text-sm'>{errors.name}</p>}
                    </div>
                    <div className='mb-4'>
                      <label className='block text-white'>Email Address</label>
                      <input
                        type='email'
                        name='email'
                        value={email}
                        onChange={(e) => setEmailId(e.target.value)}
                        className='w-full px-3 bg-[#7187ED] py-2 border rounded'
                        required
                      />
                      {errors.email && <p className='text-red-500 text-sm'>{errors.email}</p>}
                    </div>
                    <div className='mb-4'>
                      <label className='block text-white'>Phone Number</label>
                      <PhoneInput
                        country={'in'}
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        inputClass='custom-phone-input'
                        buttonClass='border rounded'
                        dropdownClass='border rounded'
                      />
                      {errors.phoneNumber && <p className='text-red-500 text-sm'>{errors.phoneNumber}</p>}
                    </div>
                    <div className='mb-4'>
                      <label className='block text-white'>Designation</label>
                      <input
                        type='text'
                        name='designation'
                        value={designation}
                        onChange={(e) => setDesignation(e.target.value)}
                        className='w-full px-3 bg-[#7187ED] py-2 border rounded'
                      />
                      {errors.designation && <p className='text-red-500 text-sm'>{errors.designation}</p>}
                    </div>

                    <div className='mb-4'>
                      <label className='block text-white'>Company Name</label>
                      <input
                        type='text'
                        name='companyName'
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        className='w-full px-3 bg-[#7187ED] py-2 border rounded'
                        required
                      />
                      {errors.companyName && <p className='text-red-500 text-sm'>{errors.companyName}</p>}
                    </div>
                    <div className='mb-4'>
                      <button type='submit' className='w-full px-4 py-3  font-bold bg-white text-[#23389D] rounded-xl'>
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Homepage;
