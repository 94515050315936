
import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Homepage from './pages/Homepage';
import  RightButtonPopup  from "./pages/RightButtonPopup";
import chatbot from "./assets/Chatbot.svg";

const App = () => {
  const [showRightButtonPopup, setShowRightButtonPopup] = useState(false);
  
  const handleRightButtonPopupClick = () => {
    setShowRightButtonPopup(true);
  };

  const handleCloseAllPopups = () => {
   
    setShowRightButtonPopup(false);
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
      </Routes>
      {showRightButtonPopup && (
        <RightButtonPopup onClose={handleCloseAllPopups} />
      )}
      <img
        src={chatbot}
        alt="Say Thanks!"
        onClick={handleRightButtonPopupClick}
        className="fixed bottom-5 right-5 w-16 h-16 cursor-pointer  z-50"
      />
    </BrowserRouter>
  );
};

export default App;
